@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

:root {
  --bg-color: #f7f7f7;
  --second-bg-color: #323946;
  --third-bg-color: #ffffff;
  --h3-bg-color: #002057;
  /* --navt-bg-color: #112734; */
  --navt-bg-color: #0e2431;
  --navhov-bg-color: #0c24ff;
  --text-color: #fff;
  --main-color: #0ef;
  --para-color: #ff7b00;
  --btn-color: #2506ad;
  --abt-color: #ecebeb;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background: var(--bg-color);
  color: var(--text-color);
}

.portfolio {
  min-height: 55vh;
  padding: 6rem 9% 2rem;
  /* background: var(--second-bg-color); */
  /* background: linear-gradient(to right, #D3CCE3, #E9E4F0); */
  /* background: linear-gradient(to right, #5b3bc6, #fffde4); */
  background: linear-gradient(to right, #ffffff, #f48217);
}
.portfolio h2 {
  margin-bottom: 5rem;
  font-weight: 500;
}

.portfolio-wrapper {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 20px; /* Space between the portfolio boxes */
  justify-content: center; /* Center the items */
  margin: 20px; /* Add some margin around the wrapper */
}

.portfolio-container {
  flex: 1 1 300px; /* Allows the items to grow and shrink, with a minimum width */
  max-width: 100%; /* Set a max-width for the portfolio boxes */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
  border-radius: 8px; /* Round the corners */
  overflow: hidden; /* Ensure no overflow on rounded corners */
}


.portfolio-container .portfolio-box {
  position: relative;
  border-radius: 0.8rem;
  /* box-shadow: 0 0 1rem var(--bg-color); */
  box-shadow: 0px 0px 2rem rgba(0, 0, 0, 0.815);
  overflow: hidden;
  display: flex;
  margin: auto;
  width: 80%;
  height: 350px;
}

.portfolio-box img {
  width: 100%;
  /* height: 50%; */
  margin: auto;
  object-fit: cover;
  transition: 0.5s ease;
}

.portfolio-box:hover img {
  transform: scale(1.1);
}

.portfolio-box .portfolio-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.1), black);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 4rem;
  transform: translateY(100%);
  transition: 0.5s ease;
}

.portfolio-box:hover .portfolio-layer {
  transform: translateY(0);
}

.portfolio-layer h4 {
  font-size: 2rem;
}

.portfolio-layer p {
  text-align: justify;
  font-size: 1.3rem;
  margin: 0.3rem 0 1rem;
}

.portfolio-layer a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  background: var(--text-color);
  border-radius: 50%;
}

.portfolio-layer a i {
  font-size: 2rem;
  color: var(--second-bg-color);
}

.p_heading {
  /* color: var(--navt-bg-color); */
  color: black;
  text-align: center;
  font-size: 4.5rem;
}

span {
  /* color: var(--main-color); */
  color: rgb(255, 123, 0);
}

/* BREAKPOINTS */
@media (max-width: 1200px) {
  /* .portfolio-container {
    grid-template-columns: 1fr;
  } */
  /* .portfolio-container {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 5.5rem;
    margin-bottom: 20px;
  } */

  .portfolio-box img {
    /* padding: 18px; */
  }
  .portfolio-container .portfolio-box {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .portfolio {
    padding-bottom: 7rem;
  }
  .portfolio-container .portfolio-box {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .portfolio h2 {
    margin-bottom: 3rem;
  }
  .portfolio-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    gap: 5.5rem;
    margin-bottom: 20px;
  }

  .portfolio-container .portfolio-box {
    width: 100%;
  }
}

@media (max-width: 617px) {
  /* .portfolio-container {
    grid-template-columns: 1fr;
  } */
  .portfolio-container {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 5.5rem;
    margin-bottom: 20px;
  }

  .portfolio-box img {
    width: 100%;
    /* padding: 20px; */
    margin: auto;
  }

  .portfolio-layer h4 {
    font-size: 1rem;
  }

  .portfolio-layer p {
    font-size: 1.2rem;
  }
  .portfolio-layer a i {
    font-size: 1rem;
  }
  .portfolio-container .portfolio-box {
    width: 100%;
  }

}
















/* FOR FLOATING COMP */
.floatsec {
  min-height: 100vh;
  padding: 5rem 9% 5rem;
  display: grid;
  place-items: center;
  font-family: "Poppins", sans-serif;
  /* background: linear-gradient(90deg, #f6f9fc 0%, #f48217 100%); */
}

.heading {
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  width: 40%;
  margin: auto;
  color: black;
  margin-bottom: 3rem;
}

.merged-containerfc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  /* margin: auto; */
  position: relative;
  width: 120%;
  overflow: hidden;
  border: 1px solid #ffffff;
  box-shadow: 0 1rem 1.5rem #1f1f1f;
}

.containerfc {
  height: 200px;
  margin: auto;
  width: 82%;
  display: grid;
  place-items: center;
  overflow: hidden;
}


.slide-containerfc {
  display: flex;
  width: calc(250px * 12);
  animation: scroll-left 20s linear infinite;
  transform: translateX(0); /* Initialize position at the start */
}

.reverse {
  animation: scroll-right 20s linear infinite;
  transform: translateX(
    calc(-250px * 6)
  );
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 6));
  }
}

@keyframes scroll-right {
  0% {
    transform: translateX(
      calc(-250px * 6)
    );
  }
  100% {
    transform: translateX(0);
  }
}

.slide-image {
  height: 250px;
  width: 250px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}

img {
  width: 90%;
  transition: transform 1s;
}

img:hover {
  transform: translateZ(20px);
}

.merged-heading {
  color: black;
  margin-top: 20px;
  margin-bottom: -30px;
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.7;
}


/* SCROLLBAR */

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, rgb(255, 123, 0), #f7f8fc, #138808);
}

/* BREAKPOINTS */
@media (max-width: 1200px) {
}

@media (max-width: 991px) {
}

@media (max-width: 768px) {
    .floatsec {
        min-height: 80vh;
        padding: 0rem 9% 5rem;
      }
    .merged-heading{
        font-size: 2rem;
        padding: 20px;
        margin: auto;
    }
    img{
        width: 90%;
    }
    .slide-image {
        height: 150px;
        width: 150px;
        /* display: flex; */
        /* align-items: center; */
        /* padding: 15px; */
        /* perspective: 100px; */
      }
}

@media (max-width: 617px) {
}
