@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

:root {
  --bg-color: #f7f7f7;
  --second-bg-color: #323946;
  --third-bg-color: #ffffff;
  --h3-bg-color: #002057;
  /* --navt-bg-color: #112734; */
  --navt-bg-color: #0e2431;
  --navhov-bg-color: #0c24ff;
  --text-color: #fff;
  --main-color: #0ef;
  --para-color: #ff7b00;
  --btn-color: #2506ad;
  --abt-color: #ecebeb;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background: var(--bg-color);
  color: var(--text-color);
}

section {
  min-height: 100vh;
  padding: 10rem 9% 2rem;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem 9%;
  /* padding: 0.5rem 4%; */
  /* background: var(--third-bg-color); */
  background: #f7f8fc;
  /* background: white; */
  /* box-shadow: 0 1rem 1.5rem #1f1f1f; */
  /* background: linear-gradient(to right, #ffffff, #f48217); */
  box-shadow: rgba(157, 157, 157, 0.2) 0px 4px 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.dashbtn {
  /* display: inline-block; */
  display: inline-flex;
  /* align-items: center; */
  padding: 10px 20px;
  background: var(--btn-color);
  background: #f7f8fc;
  border: 2px solid #6674cc;
  border: 2px solid rgb(255, 123, 0);
  border-radius: 6px;
  box-shadow: rgba(157, 157, 157, 0.2) 0px 4px 10px 0px;
  font-size: 1.6rem;
  color: black;
  letter-spacing: 0.1rem;
  font-weight: 500;
  transition: font-size 0.25s ease-in-out;
  transition: 0.5s ease;
  margin-right: 1.5rem;
}

.header.sticky {
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.2);
}

.logo {
  font-size: 2.5rem;
  color: var(--navt-bg-color);
  width: 0%;
  height: 100%;
  font-weight: bolder;
  cursor: default;
  margin-right: 2rem;
}

.navbar {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.navbar a {
  font-size: 1.7rem;
  color: var(--navt-bg-color);
  font-weight: 450;
  /* margin-left: 4rem; Remove the margin to create equal spaces between links */
  margin: 0 3rem; /* Add equal spacing between links */
  transition: 0.3s;
}

.header-image{
  all: unset !important;
}

.navbar a:hover,
.navbar a.active {
  /* color: var(--navhov-bg-color); */
  color: rgb(255, 123, 0);
  /* text-decoration: underline; */
}

#menu-icon {
  font-size: 3.6rem;

  color: var(--btn-color);
  display: none;
}
#menu-icon:hover {
  /* color: var(--btn-color); */
  color: var(--h3-bg-color);
}

.logo-img {
  width: 17%;
}

.menu-icon {
  width: 22px;
  height: 22px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  display: none;
}

.menu-icon {
  width: 22px;
  height: 22px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  display: none;
}

.line {
  width: 100%;
  height: 4px;
  background-color: black;
  opacity: 0.8;
  transition: transform 0.4s;
}

.cross .line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.cross .line:nth-child(2) {
  opacity: 0;
}

.cross .line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.courses-container {
  position: absolute;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  height: 40px;
}
.courses-items1 {
  /* background-color:#ff7b00; */
  display: flex;
  width: 207px;
  font-size: 1.3rem;
  padding: 20px 5px;
  /* box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -4px rgba(0,0,0,.1); */
  color: black;
  font-weight: 500;
}
.items1-icon {
  color: #000;
  align-self: center;
  padding-right: 10px;
  margin-bottom: 3px;
  font-size: 1.2rem;
}
.items1-container {
  display: flex;
  flex-direction: column;
  height: 140px;
  justify-content: space-around;
  background: #f7f8fc;
  box-shadow: rgba(157, 157, 157, 0.2) 0px 4px 10px 0px;
  /* display: inline; */
}
.items2-container {
  position: absolute;
  left: 230px;
  background: #f7f8fc;
  box-shadow: rgba(157, 157, 157, 0.2) 0px 4px 10px 0px;
  height: 250px;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

.courses-items2 {
  padding: 5px 10px;
  font-size: 1.2rem;
  color: black;
  font-weight: 450;
  width: 170px;
  margin-top: 5px;
}

.courses-items2 > a {
  font-size: 1.2rem;
  text-decoration: none;
  margin: 0;
}

.courses-items1:hover {
  cursor: pointer;
}

.courses-items2:hover {
  cursor: pointer;
}

.cursor-animation {
  display: flex;
  cursor: pointer;
}
.icon-transfrom {
  transition: all 0.5ns ease-in-out;
  transform: rotate(180deg);
  margin-left: -10px;
}

/* SCROLLBAR */

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  /* background: -webkit-gradient(linear, left top, left bottom, from (#ff8a00), to (#da1b60)); */
  /* background: linear-gradient(to bottom, #ff8a00, #da1b60); */
  /* background: linear-gradient(to bottom, var(--h3-bg-color), #da1b60); */
  background: linear-gradient(to bottom, rgb(255, 123, 0), #f7f8fc, #138808);
}

.login-cta {
  height: 50px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff8a00;
  border-radius: 12px;
  color: #f7f8fc;
  font-size: 1.5rem;
  font-weight: 500;
}
.dashboard-cta {
  height: 50px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c24ff;
  border-radius: 12px;
  color: #f7f8fc;
  font-size: 1.5rem;
  font-weight: 500;
  margin-left: 10px;
}

.login-cta:hover {
  text-decoration: none;
  color: white;
}
.dashboard-cta:hover {
  text-decoration: none;
  color: white;
}
@media (max-width: 768px) {
  .logo-img {
    width: 29.7%;
    /* width: 178.427px; */
  }

  .menu-icon {
    display: flex;
  }
  .courses-container {
    position: relative;
    right: 33px;
    bottom: 30px;
  }
  .items1-container {
    box-shadow: none;
    height: 0px;
    justify-content: flex-start;
  }
  .items1-icon {
    display: none;
  }
  .items2-container {
    display: none;
  }
}
.hr-divider {
  margin-top: 10px;
  border: none;
  border-top: 1px solid #ccc;
}

.items2-container::-webkit-scrollbar {
  width: 0; /* Hide the scrollbar */
  background-color: transparent; /* Optional: Set a background color */
}

/* BREAKPOINTS */
@media (max-width: 1200px) {
  html {
    font-size: 55%;
  }

  .about img {
    width: 38vw;
    margin-top: 2rem;
  }

  .portfolio-container {
    grid-template-columns: 1fr;
  }

  .portfolio-box img {
    padding: 18px;
  }
}

@media (max-width: 991px) {
  .header {
    padding: 2rem 3%;
  }
  section {
    padding: 10rem 3% 2rem;
  }

  .services {
    padding-bottom: 7rem;
  }

  .portfolio {
    padding-bottom: 7rem;
  }

  .contact {
    min-height: auto;
  }

  .footer {
    padding: 2rem 3%;
  }
}

@media (max-width: 768px) {
  #menu-icon {
    display: block;
  }
  .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 1rem 3%;
    background: var(--bg-color);
    border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    display: none;
  }

  .navbar.active {
    display: block;
  }

  .navbar a {
    display: block;
    font-size: 2rem;
    margin: 3rem 0;
  }

  .about {
    flex-direction: column-reverse;
  }
  .about img {
    width: 38vw;
    margin-top: 4rem;
  }

  .services h2 {
    margin-bottom: 3rem;
  }

  .portfolio h2 {
    margin-bottom: 3rem;
  }
  .portfolio-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .contact form {
    margin: auto;
  }
}

@media (max-width: 617px) {
  .about img {
    width: 38vw;
    margin-top: 2rem;
  }

  .portfolio-container {
    grid-template-columns: 1fr;
  }

  .portfolio-box img {
    padding: 40px;
  }
  .dashboard-cta {
    font-size: 1rem;
    width: 80px;
  }
  .login-cta {
    font-size: 1rem;
    width: 80px;
  }
}
