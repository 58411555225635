.cardContainer {
  height: 350px;
  width: 350px;
  background: #fff;
  padding: 30px 10px;
  color: black;
  border-radius: 12px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
}

.cardContainer:hover {
  transition: all 0.44s ease-in-out;
  transform: scale(1.05);
}

.card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-heading {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
}

.card-description {
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: 20px;
  padding: 0 20px;
  color: rgb(119 124 133/1);
}

@media only screen and (max-width: 700px) {
  .cardContainer {
    width: 80%;
  }
}
@media only screen and (max-width: 500px) {
  .card-description {
    font-size: 1.2rem;
  }
}
