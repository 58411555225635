.why-us-container {
  padding: 5rem 0% 0rem;
  /* min-height: 80vh;
    background: linear-gradient(to right, #ffffff, #ff7b00); */
  background: linear-gradient(to right, #ffffff, #f48217);
}

.sub-heading {
  text-align: center;
  font-weight: 800;
  text-transform: uppercase;
  padding: 10px 0;
  font-size: 2rem;
  color: #4a4f5c;
}

.heading {
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  width: 40%;
  margin: auto;
  color: black;
}

.cards-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  margin: auto;
  flex-wrap: wrap;
  padding: 20px;
}

.cards-container > div {
  margin: 40px auto;
}

/* SCROLLBAR */

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  /* background: -webkit-gradient(linear, left top, left bottom, from (#ff8a00), to (#da1b60)); */
  /* background: linear-gradient(to bottom, #ff8a00, #da1b60); */
  /* background: linear-gradient(to bottom, var(--h3-bg-color), #da1b60); */
  background: linear-gradient(to bottom, rgb(255, 123, 0), #f7f8fc, #138808);
}

@media only screen and (max-width: 700px) {
  .heading {
    font-size: 2.4rem;
    width: 80%;
  }
  .cards-container {
    width: 100%;
  }
  .cards-container > div {
    margin: 20px auto;
  }
}
