@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

:root {
  --bg-color: #f7f7f7;
  --second-bg-color: #323946;
  --third-bg-color: #ffffff;
  --h3-bg-color: #002057;
  /* --navt-bg-color: #112734; */
  --navt-bg-color: #0e2431;
  --navhov-bg-color: #0c24ff;
  --text-color: #fff;
  --main-color: #0ef;
  --para-color: #ff7b00;
  --btn-color: #2506ad;
  --abt-color: #ecebeb;
}
.dashbtn:hover {
  cursor: pointer;
  text-decoration: none;
  color: black;
}
html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background: var(--bg-color);
  color: var(--text-color);
}

section {
  /* min-height: 100vh; */
  /* padding: 10rem 9% 2rem; */
}

.dashbtn {
  /* display: inline-block; */
  display: inline-flex;
  /* align-items: center; */
  padding: 10px 20px;
  background: var(--btn-color);
  background: #f7f8fc;
  border: 2px solid #6674cc;
  border: 2px solid rgb(255, 123, 0);
  border-radius: 6px;
  box-shadow: rgba(157, 157, 157, 0.2) 0px 4px 10px 0px;
  font-size: 1.6rem;
  color: black;
  letter-spacing: 0.1rem;
  font-weight: 500;
  transition: font-size 0.25s ease-in-out;
  transition: 0.5s ease;
  margin-right: 1.5rem;
}

.home {
  /* background: linear-gradient(to right, #D3CCE3, #E9E4F0) ;
    background: linear-gradient(to right, #ADA996,#F2F2F2,#DBDBDB,#EAEAEA) ; */
  /* background: linear-gradient(to right, #F0F2F0,#000C40) ; */
  background: white;
  /* background: #0b0604; */
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateZ(0);
  /* background: linear-gradient(to right, #ffffff, #f48217); */
}

.home-img img {
  /* border: 1px solid #0b0604; */
  /* width: 35vw; */
  /* border-radius: 100%; */
  margin: auto;
  margin-top: auto;
  margin-left: 4rem;
  width: 75vw;
}

.home-content {
  width: 120%;
}

.home-content h3 {
  /* width: 90%; */
  font-size: 2.8rem;
  /* color: var(--h3-bg-color); */
  color: #4a4f5c;
  font-family: Rubik, sans-serif;
  /* letter-spacing: 0.1cm; */
  word-spacing: 0.1cm;
  /* color: pink; */
  font-weight: 500;
}

.home-content h3:nth-of-type(2) {
  margin-bottom: 2rem;
}

span {
  /* color: var(--main-color); */
  color: rgb(255, 123, 0);
}

.andI {
  position: relative;
  color: var(--navt-bg-color) !important;
  font-weight: 600 !important;
}

.home-content h1 {
  font-size: 5.6rem;
  color: var(--h3-bg-color);
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 2rem;
}

.home-content p {
  /* color: var(--h3-bg-color); */
  color: black;
  font-size: 1.6rem;
  font-weight: 450;
}

.blink::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 50px;
  margin-left: 10px;
  margin-bottom: 23px;
  background-color: rgb(255, 123, 0); /* Change this to the desired color */
  animation: blink 1s infinite; /* Apply the 'blink' animation with 1-second duration and run it infinitely */
  bottom: 0; /* Position the cursor at the bottom of the text */
}

@keyframes blink {
  0% {
    opacity: 0; /* Start with opacity 0, making the cursor invisible */
  }
  50% {
    opacity: 1; /* At 50% of the animation, the cursor is visible */
  }
  100% {
    opacity: 0; /* At the end of the animation (100%), the cursor becomes invisible again */
  }
}

.social-media a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background: var(--navt-bg-color);
  border: 0.1rem solid var(--main-color);
  border-radius: 50%;
  font-size: 2rem;
  color: var(--main-color);
  margin: 3rem 1.5rem 4rem 0;
  transition: 0.5s ease;
}
.social-media a:hover {
  /* background: var(--main-color); */
  color: #feb703;
  box-shadow: 0 0 0.7rem var(--main-color);
}

#trthis {
  display: inline-flex;
  align-items: center;
}

#trthis:hover {
  color: #feb703;
}

#termm {
  font-size: 2.3rem;
  margin-left: 6px;
}

.btn {
  display: inline-block;
  padding: 1rem 2.8rem;
  background: var(--btn-color);
  border-radius: 4rem;
  box-shadow: 0 0 0.5rem var(--main-color);
  font-size: 1.6rem;
  color: white;
  letter-spacing: 0.1rem;
  font-weight: 500;
  transition: font-size 0.25s ease-in-out;
  /* transition: 0.5s ease; */
}

.btn:hover {
  background: var(--h3-bg-color);
  box-shadow: none;
  font-size: 1.75rem;
  color: white;
  /* transition: 1s ease-in; */
}

/* BREAKPOINTS */
@media (max-width: 1200px) {
  html {
    font-size: 55%;
  }

  @media (max-width: 991px) {
    section {
      padding: 10rem 3% 2rem;
    }

    .home-content {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .home {
      flex-direction: column;
    }

    .home-content {
      width: 100%;
    }
    .home-content h3 {
      font-size: 2.6rem;
    }
    .home-content h1 {
      font-size: 5rem;
    }
    .home-img img {
      width: 60vw;
      margin: auto;
      margin-top: 4rem;
    }
  }

  @media (max-width: 617px) {
    .home-content {
      width: 100%;
    }
    .home-img img {
      width: 90vw;
      margin: auto;
      margin-top: 4rem;
      margin-bottom: -1rem;
    }
  }
  .home-content h3 {
    font-size: 2.6rem;
  }
  .home-content h1 {
    font-size: 5rem;
  }
  .home-img img {
    width: 50vw;
    margin: auto;
    margin-top: 4rem;
  }
}

@media (max-width: 617px) {
  .home-img img {
    width: 70vw;
    margin: auto;
    margin-top: 4rem;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}
