@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

:root {
  --bg-color: #f7f7f7;
  --second-bg-color: #323946;
  --third-bg-color: #ffffff;
  --h3-bg-color: #002057;
  /* --navt-bg-color: #112734; */
  --navt-bg-color: #0e2431;
  --navhov-bg-color: #0c24ff;
  --text-color: #fff;
  --main-color: #0ef;
  --para-color: #ff7b00;
  --btn-color: #2506ad;
  --abt-color: #ecebeb;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background: var(--bg-color);
  color: var(--text-color);
}

section {
  min-height: 100vh;
  padding: 10rem 9% 2rem;
}

.site-footer {
  /* background-color: #26272b; */
  /* background: var(--h3-bg-color); */
  /* background: linear-gradient(to left, #5b3bc6, #F0F2F0); */
  /* background: #6b6778; */
  /* background: linear-gradient(to right, #5a5656, #8f622f); */
  background: #5a5656;
  background: rgb(75, 74, 74);
  padding: 25px 25px 20px;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
  color: rgb(210, 205, 205);
  color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  /* border-radius: 10px; */
  margin-top: 10px;
}
.site-footer hr {
  border-top-color: #ffffff;
  opacity: 1;
}
.site-footer hr.small {
  margin: 20px 0;
}
.site-footer h2 {
  /* color: #fff; */
  /* color: black; */
  color: var(--para-color);
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 14px;
  letter-spacing: 2px;
}
.site-footer a {
  color: #737373;
}
.site-footer a:hover {
  color: #fff;
  text-decoration: none;
}
.footer-links {
  padding-left: 0;
  list-style: none;
}
.footer-links li {
  display: block;
}
.footer-links a {
  color: #737373;
  color: rgb(210, 205, 205);
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #fff;
  text-decoration: none;
}
.site-footer .social-icons {
  text-align: center;
  margin-left: 18rem;
}

.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0 1rem;
  border-radius: 100%;
  background-color: #33353d;
}
.copyright-text {
  margin: 0;
}
@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0;
  }
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}
.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
  margin-right: 10px;
}
.social-icons a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background: var(--navt-bg-color);
  border: 0.1rem solid var(--main-color);
  border-radius: 50%;
  font-size: 2rem;
  color: var(--main-color);
  /* margin: 0rem 10rem 3rem 0; */
  transition: 0.5s ease;
}

.social-icons a.leet {
  /* margin-right: 80px; */
}

.social-icons a:hover {
  color: #fec903;
  box-shadow: 0 0 0.7rem var(--main-color);
}

.logo-footer{
  width: 80%;
}

/* SCROLLBAR */

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  /* background: -webkit-gradient(linear, left top, left bottom, from (#ff8a00), to (#da1b60)); */
  /* background: linear-gradient(to bottom, #ff8a00, #da1b60); */
  /* background: linear-gradient(to bottom, var(--h3-bg-color), #da1b60); */
  background: linear-gradient(to bottom, rgb(255, 123, 0), #f7f8fc, #138808);
}

/* BREAKPOINTS */
@media (max-width: 1200px) {
  html {
    font-size: 55%;
  }

  .about img {
    width: 38vw;
    margin-top: 2rem;
  }

  .portfolio-container {
    grid-template-columns: 1fr;
  }

  .portfolio-box img {
    padding: 18px;
  }
}

@media (max-width: 991px) {
  .header {
    padding: 2rem 3%;
  }
  section {
    padding: 10rem 3% 2rem;
  }

  .services {
    padding-bottom: 7rem;
  }

  .portfolio {
    padding-bottom: 7rem;
  }

  .contact {
    min-height: auto;
  }

  .footer {
    padding: 2rem 3%;
  }
  .site-footer .social-icons {
    text-align: center;
    margin: auto;
  }
}

@media (max-width: 768px) {
  #menu-icon {
    display: block;
  }
  .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 1rem 3%;
    background: var(--bg-color);
    border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    display: none;
  }

  .navbar.active {
    display: block;
  }

  .navbar a {
    display: block;
    font-size: 2rem;
    margin: 3rem 0;
  }
  .about {
    flex-direction: column-reverse;
  }
  .about img {
    width: 38vw;
    margin-top: 4rem;
  }

  .services h2 {
    margin-bottom: 3rem;
  }

  .site-footer .social-icons {
    text-align: center;
    margin: auto;
  }

  .portfolio h2 {
    margin-bottom: 3rem;
  }
  .portfolio-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .contact form {
    margin: auto;
  }
  .logo-footer{
    /* text-align: center; */
    margin: auto;
    margin-left: 55%;
    margin-bottom: 2rem;

  }

  .tagline{
    display: none !important; 
    /* text-align: center; */
    width: 100%;
    margin-left: 13rem;
    margin-bottom: 4rem;
  }
}

@media (max-width: 617px) {
  .about img {
    width: 38vw;
    margin-top: 2rem;
  }

  .portfolio-container {
    grid-template-columns: 1fr;
  }

  .portfolio-box img {
    padding: 40px;
  }
  .site-footer .social-icons {
    text-align: center;
    margin: auto;
  }
  .logo-footer{
    /* text-align: center; */
    margin: auto;
    margin-left: 55%;
    margin-bottom: 2rem;

  }

  .tagline{
    display: none !important; 
    /* text-align: center; */
    width: 100%;
    margin-left: 13rem;
    margin-bottom: 4rem;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
