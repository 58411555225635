@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

:root {
  --bg-color: #f7f7f7;
  --second-bg-color: #323946;
  --third-bg-color: #ffffff;
  --h3-bg-color: #002057;
  /* --navt-bg-color: #112734; */
  --navt-bg-color: #0e2431;
  --navhov-bg-color: #0c24ff;
  --text-color: #fff;
  --main-color: #0ef;
  --para-color: #ff7b00;
  --btn-color: #2506ad;
  --abt-color: #ecebeb;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background: var(--bg-color);
  color: var(--text-color);
}

section {
  min-height: 100vh;
  padding: 10rem 9% 2rem;
}

.heading {
  color: black;
  text-align: center;
  font-size: 4.5rem;
}

#alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--h3-bg-color) !important;
  background: #323946 !important;
  color: var(--text-color);
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 18px;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

span {
  /* color: var(--main-color); */
  color: rgb(255, 123, 0);
}

.btn {
  display: inline-block;
  padding: 1rem 2.8rem;
  background: var(--btn-color);
  border-radius: 4rem;
  box-shadow: 0 0 0.5rem var(--main-color);
  font-size: 1.6rem;
  color: white;
  letter-spacing: 0.1rem;
  font-weight: 500;
  transition: font-size 0.25s ease-in-out;
  /* transition: 0.5s ease; */
}

.btn:hover {
  background: var(--h3-bg-color);
  box-shadow: none;
  font-size: 1.75rem;
  color: white;
  /* transition: 1s ease-in; */
}

.contact {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background: white;
  background: #323946;
  /* background: #e4e5e6; */
  /* background: linear-gradient(to right, #ffffff, #f48217); */
  /* background: linear-gradient(to left, #ffffff, #138808); */
  background: linear-gradient(to left, #ffffff, #6f51d2);
  background: white;
}

.contact h2 {
  width: 100%;
  margin-bottom: 4rem;
  margin-top: -6rem;
}
.contact form {
  width: 49rem;
  /* max-width: 205rem; */
  margin: 1rem;
  text-align: center;
  margin-bottom: 3rem;
}
.contact form .input-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact form .input-box input,
.contact form textarea {
  width: 100%;
  padding: 1.5rem;
  font-size: 1.4rem;
  color: var(--text-color);
  color: white;
  /* background: var(--second-bg-color); */
  /* background: #1b2c77; */
  /* background: #6f51d2; */
  /* background: #f38f32; */
  background: #939598;

  /* background: rgba(255, 255, 255, 0.1); */
  /* background: linear-gradient(to left, #ffffff, #6f51d2); */
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);

  border-radius: 0.8rem;
  margin: 0.7rem 0;
}
::placeholder {
  /* color: rgb(232, 219, 221); */
  color: white !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: white !important;
}

::-ms-input-placeholder {
  color: white !important;
}

.contact form .input-box input {
  width: 49%;
}

.contact form textarea {
  resize: none;
}

.contact form .btn {
  /* background: #3d1ab0; */
  background: #939598;
  /* box-shadow: 0 0 0.5rem var(--h3-bg-color); */
  box-shadow: 0 0 0.8rem #939598;
  margin-top: 2rem;
  cursor: pointer;
}

.contact form .btn:hover {
  /* background: var(--h3-bg-color); */
  background: #939598;
  box-shadow: none;
  font-size: 1.75rem;
  /* color:#feb703; */
  /* transition: 1s ease-in; */
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.cont-img {
  margin-top: 40px;
  margin-right: -60px;
  width: 100%;
  /* max-width: 100%; */
  height: auto;
}

/* SCROLLBAR */

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  /* background: -webkit-gradient(linear, left top, left bottom, from (#ff8a00), to (#da1b60)); */
  /* background: linear-gradient(to bottom, #ff8a00, #da1b60); */
  /* background: linear-gradient(to bottom, var(--h3-bg-color), #da1b60); */
  background: linear-gradient(to bottom, rgb(255, 123, 0), #f7f8fc, #138808);
}

/* BREAKPOINTS */
@media (max-width: 1200px) {
  html {
    font-size: 55%;
  }

  .about img {
    width: 38vw;
    margin-top: 2rem;
  }

  .portfolio-container {
    grid-template-columns: 1fr;
  }

  .portfolio-box img {
    padding: 18px;
  }
}

@media (max-width: 991px) {
  .header {
    padding: 2rem 3%;
  }
  section {
    padding: 10rem 3% 2rem;
  }

  .services {
    padding-bottom: 7rem;
  }

  .portfolio {
    padding-bottom: 7rem;
  }

  .contact {
    min-height: auto;
  }

  .footer {
    padding: 2rem 3%;
  }
}

@media (max-width: 768px) {
  #menu-icon {
    display: block;
  }
  .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 1rem 3%;
    background: var(--bg-color);
    border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    display: none;
  }

  .navbar.active {
    display: block;
  }

  .navbar a {
    display: block;
    font-size: 2rem;
    margin: 3rem 0;
  }
  .about {
    flex-direction: column-reverse;
  }
  .about img {
    width: 38vw;
    margin-top: 4rem;
  }

  .services h2 {
    margin-bottom: 3rem;
  }

  .portfolio h2 {
    margin-bottom: 3rem;
  }
  .portfolio-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .contact form {
    margin: auto;
  }

  .cont-img {
    max-width: fit-content;
    width: 60vw;
    margin: auto;
    margin-top: 4rem;
  }
}

@media (max-width: 617px) {
  .about img {
    width: 38vw;
    margin-top: 2rem;
  }

  .portfolio-container {
    grid-template-columns: 1fr;
  }

  .portfolio-box img {
    padding: 40px;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
