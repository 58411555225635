@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

:root {
  --bg-color: #f7f7f7;
  --second-bg-color: #323946;
  --third-bg-color: #ffffff;
  --h3-bg-color: #002057;
  /* --navt-bg-color: #112734; */
  --navt-bg-color: #0e2431;
  --navhov-bg-color: #0c24ff;
  --text-color: #fff;
  --main-color: #0ef;
  --para-color: #ff7b00;
  --btn-color: #2506ad;
  --abt-color: #ecebeb;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background: var(--bg-color);
  color: var(--text-color);
}

section {
  min-height: 100vh;
  padding: 10rem 9% 2rem;
}

.instructor-container {
  /* padding: 30px 7.5%; */
  padding: 7rem 7.5% 4.5rem;
  background: #fff;
  min-height: 80vh;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-size: 2rem;
}

.instructor-heading {
  font-size: 4rem;
  font-weight: 600;
}

.heading-color {
  color: #ff7b00;
}

.know-more-button {
  background: #ff7b00;
  height: 50px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
}

.know-more-button > a:hover {
  text-decoration: none;
  color: #fff;
}

.bottom-separator {
  margin-top: 20px;
  height: 1.2px;
  background: #000;
}

.icon-experience-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
  width: 90%;
  margin: auto;
}

.icon-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.icon_container {
  height: 300px;
  width: 300px;
  object-fit: contain;
  border-radius: 50%;
}

.icon-title {
  font-size: 3rem;
  font-weight: 600;
  color: #000;
  margin-top: 10px;
}

.icon-subtitle {
  font-size: 2.5rem;
  font-weight: 600;
  color: rgb(119 124 133);
}

.exp-container {
  width: 50%;
}

.about-me {
  color: #ff7b00;
  font-size: 1.3rem;
  font-weight: 800;
  text-transform: uppercase;
}

.about-me-heading {
  font-size: 2.8rem;
  font-weight: 700;
  color: #000;
}

.about-me-subheading {
  color: rgb(119 124 133);
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 10px;
}

.exp-container-1 {
  display: flex;
  background: #e6f9ff;
  height: 100px;
  border-radius: 12px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  width: 30vw;
  font-size: 2rem;
  font-weight: 700;
  padding: 0 3rem;
}

.exp-container-1 > div {
  background: #e6f9ff;
  color: #003366;
}

.exp-container-2 {
  background: #f7e6ff;
}

.exp-container-2 > div {
  background: #f7e6ff;
  color: #660099;
}
.know-more-cta {
  height: 50px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff8a00;
  border-radius: 12px;
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: underline;
}

/* SCROLLBAR */

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  /* background: -webkit-gradient(linear, left top, left bottom, from (#ff8a00), to (#da1b60)); */
  /* background: linear-gradient(to bottom, #ff8a00, #da1b60); */
  /* background: linear-gradient(to bottom, var(--h3-bg-color), #da1b60); */
  background: linear-gradient(to bottom, rgb(255, 123, 0), #138808);
}

@media only screen and (max-width: 700px) {
  .instructor-heading {
    font-size: 2rem;
  }
  .icon-experience-parent {
    flex-direction: column;
  }
  .about-me-heading {
    font-size: 1.5rem;
  }
  .exp-container {
    margin-top: 30px;
    width: 100%;
  }
  .exp-container-1 {
    width: 100%;
  }
}
