.hero-section {
  height: 50vh;
  background: linear-gradient(to right, #f6b476, #f48217);
  width: 100%;
  color: white;
  /* padding: 240px 7.5%; */
  padding: 240px 7.5% 190px;
  /* padding: 28rem 7.5% 20rem 7.5%; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.text-header-icon-container {
  width: 60%;
}

.icon-header {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 30px;

  & > i {
    margin: 0 10px;
  }
}

.section-heading {
  color: #fff;
  font-size: 3.5rem;
  font-weight: 600;
}

.section-subheading {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 20px;
}

.course-container {
  min-height: 53vh;
  background: #fff;
  width: 350px;
  margin-top: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 10px 20px;
  z-index: 99;
  transition: all 9ns ease-in-out;
}

.course-heading {
  font-size: 2rem;
  color: #000;
  font-weight: 600;
  margin-top: 10px;
}

.course-subheading {
  color: #2d2f31;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 12px;
}

.course-cta {
  height: 50px;
  width: 100%;
  color: #fff;
  font-size: 1.5rem;
  background: #a435f0;
  font-weight: 500;
  margin-top: 20px;
}

.course-price-text {
  color: #000;
  font-size: 2.4rem;
  margin: 15px 0;
  font-weight: 600;
}

.or-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > span {
    color: #6a6f73;
    margin: 0 10px;
  }
}

.or-container > div {
  height: 1px;
  background: #6a6f73;
  width: 40%;
}

.course-buy-cta {
  height: 50px;
  width: 100%;
  color: #000;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 10px;
  border: solid 1px black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.course-buy-cta:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.course-minor-text {
  color: #6a6f73;
  font-size: 1.2rem;
  margin-top: 10px;
  text-align: center;
}

.coupon-gift-container {
  margin: 20px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.3rem;
  color: #000;
  font-weight: 600;
  text-decoration: underline;
}

.absolute-class {
  position: absolute;
}
.buyCourse-cta {
  height: 50px;
  width: 120px;
  display: none;
  justify-content: center;
  align-items: center;
  background: #f18706;
  border-radius: 12px;
  color: #f7f8fc;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 10px;
}
.buyCourse-cta:hover {
  text-decoration: none;
  background-color: white;
  color: #f18706;
}
@media (max-width: 1125px) {
  .hero-section {
    height: 65vh;
  }
  .hero-section {
    /* padding: 240px 7.5%; */
    /* padding-top: 350px; */
    padding: 350px 7.5% 260px;
    /* padding: 40% 7.5% 28%; */
  }
}

@media (max-width: 700px) {
  .text-header-icon-container {
    width: 95%;
  }
  .course-container {
    display: none;
  }
  .buyCourse-cta {
    display: flex;
  }
  .hero-section {
    /* padding: 240px 7.5%; */
    /* padding-top: 350px; */
    padding: 350px 7.5% 260px;
  }
}

@media (max-width: 420px) {
  .hero-section {
    height: 60vh;
  }
  .section-subheading {
    display: none;
  }
  .hero-section {
    /* padding: 240px 7.5%; */
    /* padding-top: 350px; */
    padding: 280px 7.5% 200px;
  }
}
