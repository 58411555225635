/* body {
    font-family: "Karla", sans-serif;
    background: linear-gradient(to left, #5a5959, #f48217);
    color: #ffffff;
    padding-bottom: 75px;
  }
  @media (max-width: 767px) {
    body {
      padding-bottom: 30px;
    }
  } */
  
  /* @media (max-width: 991px) {
    .section-left {
      padding: 24px 40px;
    }
  } */
  .my-auto{
    padding-top: 22px;
  }
  .page-title {
    width: 150%;
    font-size: 40px;
    padding-top: 40vh;
  }
  .img-fluid{
    width: 100%;
    margin: auto;
    margin-left: 2rem;
    /* height: 100%; */
  }
  @media (max-width: 991px) {
    .page-title {
      font-size: 15px;
      margin-bottom: 48px;
      /* margin-top: 0rem; */
    }
  }
  @media (max-width: 767px) {
    /* .page-title {
      font-size: 30px;
      margin-bottom: 48px;
      margin-top: -5rem;
    } */
    .page-title {
      width: 100%;
      font-size: 30px;
      padding-top: 5vh;
      text-align: center;
    }
    .img-fluid{
      width: 100%;
      margin: auto;
      /* margin-top: -8rem; */
      /* margin-left: 2rem; */
      /* height: 100%; */
    }
  }
  .footer-social-links {
    margin-bottom: 25px;
  }
  .footer-social-links .social-link {
    display: inline-block;
    text-align: center;
    line-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ffffff;
    color: #000000;
    font-size: 20px;
    margin-right: 16px;
    transition: all 0.3s ease-in-out;
  }
  .footer-social-links .social-link:last-child {
    margin-right: 0;
  }
  .footer-social-links .social-link:hover {
    text-decoration: none;
    background-color: #000000;
    color: #ffffff;
  }

  .footer-text{
    font-size: 1.5rem;
    /* margin: auto; */
    /* padding: 2px; */
  }
  
  #alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: var(--h3-bg-color); */
    background-color: white;
    color: var(--text-color);
    color: black;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 18px;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  @media (max-width: 767px) {
    .footer-social-links .social-link {
      line-height: 30px;
      width: 30px;
      height: 30px;
      font-size: 14px;
    }
  }
  
  /*# sourceMappingURL=bd-coming-soon.css.map */
  